import React from 'react';
import { Box, Button, Fade, Typography } from '@mui/material';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import EmailIcon from '@mui/icons-material/Email';
import GitHubIcon from '@mui/icons-material/GitHub';

function Intro(props) {

    const openLink = type => {
        switch (type) {
            case 'email':
                window.open("mailto:pablo@herrera.net.ar", "_blank");
                break;
            case 'instagram':
                window.open("https://www.instagram.com/pabloherre/", "_blank");
                break;
            case 'linkedin':
                window.open("https://www.linkedin.com/in/pablo-ariel-herrera-45608015/", "_blank");
                break;
            case 'github':
                window.open("https://github.com/pabloherre", "_blank");
                break;
            default:
                break;
        }
    }

    return (
        <Box height="90vh" justifyContent="center" display="flex" alignItems="center" flexDirection="column">
            <Fade in={true} timeout={1000}>
                <Typography sx={{ fontSize: { xs: '3rem', md: '5rem' } }}
                            paragraph>Hello <span>&#128075;&#127996;</span></Typography>
            </Fade>
            <Fade in={true} timeout={2000}>
                <Typography sx={{ fontSize: { xs: '2rem', md: '3rem' } }} textAlign="left">I'm Pablo Ariel Herrera, fullstack software developer, working
                    remotely from
                    Mendoza, Argentina.</Typography>
            </Fade>
            <Box display="flex" alignItems="center" alignSelf="flex-start" gap={1} mt={4}>
                <Fade in={true} timeout={2000}>
                    <Button variant="outlined" onClick={() => openLink("email")}><EmailIcon fontSize="small"/></Button>
                </Fade>
                <Fade in={true} timeout={2300}>
                    <Button variant="outlined" onClick={() => openLink("instagram")}><InstagramIcon

                        fontSize="small"/></Button>
                </Fade>
                <Fade in={true} timeout={2600}>
                    <Button variant="outlined" onClick={() => openLink("linkedin")}><LinkedInIcon
                        fontSize="small"/></Button>
                </Fade>
                <Fade in={true} timeout={2900}>
                    <Button variant="outlined" onClick={() => openLink("github")}><GitHubIcon
                        fontSize="small"/></Button>
                </Fade>
            </Box>
        </Box>
    );
}

export default Intro;
